import { Component } from 'preact';

/**
 * This higher order component will wrap a component in logic that monitors window width.
 * It will pass windowWidth as an additional prop down into the wrapped component, thus
 * enabling the wrapped component to be size aware.
 */
const sizeAware = (WrappedComponent) => {

	return class extends Component {

		constructor(props, context) {
			super(props, context);
			this.state = {
				windowWidth: window.innerWidth
			};
		}

		componentDidMount() {
			this.handlerId = this.context.resize.addHandler(() => {
				this.setState({ windowWidth: window.innerWidth });
			});
		}

		componentWillUnmount() {
			this.context.resize.removeHandler(this.handlerId);
		}

		render(props) {
			return <WrappedComponent {...props} windowWidth={this.state.windowWidth} />;
		}

	};

};

export default sizeAware;
