import style from './style.scss';

/**
 * The Footer component displays the website footer, with copyright and build version.
 */
const Footer = () => {

	const year = new Date().getFullYear();
	const version = window.HowickWeather.version;

	return (
		<footer class={style.footer}>
			<div>Copyright &copy; { year } howickweather.co.nz</div>
			<div>Version: <strong>{ version }</strong></div>
		</footer>
	);

};

export default Footer;
