import { Component } from 'preact';
import { Link } from 'preact-router/match';

import style from './style.scss';
import sizeAware from '../size-aware';
import SubMenu from './submenu';
import DropdownIcon from '../icons/dropdown';

/**
 * The Navigation component takes care of the app navigation. It provides
 * a fully functional accessible menu with a dropdown submenu for the history
 * section, and on small screens the last several items become grouped in
 * a generic More items submenu.
 *
 * props:
 * - activeUrl: the currently active url in the application
 */
class Navigation extends Component {

	constructor(props) {
		super(props);

		this.toggleMoreItems = this.toggleMoreItems.bind(this);
		this.toggleHistoryItems = this.toggleHistoryItems.bind(this);
		this.moreItemsMouseover = this.moreItemsMouseover.bind(this);
		this.moreItemsMouseout = this.moreItemsMouseout.bind(this);
		this.historyMouseover = this.historyMouseover.bind(this);
		this.historyMouseout = this.historyMouseout.bind(this);

		this.state = {
			isHistoryExpanded: false,
			isMoreItemsExpanded: false
		};

		this.moreItemsData = [
			{ route: '/about', label: 'About' },
			{ route: '/news', label: 'News' },
			{ route: '/settings', label: 'Settings' }
		];

		this.historyItemsData = [
			{
				route: '/history-daily',
				label: 'Daily',
				pattern: /^\/history-daily(\/[0-9]{4}-[0-9]{2}-[0-9]{2})?$/
			},
			{
				route: '/history-monthly',
				label: 'Monthly',
				pattern: /^\/history-monthly(\/(january|february|march|april|may|june|july|august|september|october|november|december)-[0-9]{4})?$/
			},
			{
				route: '/history-yearly',
				label: 'Yearly',
				pattern: /^\/history-yearly(\/[0-9]{4})?$/
			},
			{
				route: '/history-records/overall',
				label: 'Records',
				pattern: /^\/history-records\/(overall|top-ten|monthly(\/(january|february|march|april|may|june|july|august|september|october|november|december))?)$/
			},
			{
				route: '/history-climate',
				label: 'Climate'
			},
			{
				route: '/history-rainfall',
				label: 'Rainfall'
			},
			{
				route: '/history-download/daily',
				label: 'Download',
				pattern: /^\/history-download\/(daily|monthly|observations)$/
			}
		];

	}

	toggleMoreItems(isMoreItemsExpanded) {
		this.setState({ isMoreItemsExpanded });
	}

	toggleHistoryItems(isHistoryExpanded) {
		this.setState({ isHistoryExpanded });
	}

	// When the more items menu is moused over, expand it.
	moreItemsMouseover() {
		if (!this.state.isMoreItemsExpanded) {
			this.toggleMoreItems(true);
		}
	}

	// When the more items menu is moused out, collapse it.
	moreItemsMouseout(evt) {
		if (!document.querySelector('#hw_more_items_submenu').contains(evt.relatedTarget)) {
			this.toggleMoreItems(false);
		}
	}

	historyMouseover() {
		if (!this.state.isHistoryExpanded) {
			this.toggleHistoryItems(true);
		}
	}

	historyMouseout(evt) {
		if (!document.querySelector('#hw_history_submenu').contains(evt.relatedTarget)) {
			this.toggleHistoryItems(false);
		}
	}

	render(props) {

		let moreItems;

		if (props.windowWidth < 450) {

			const moreItemsSubmenuClasses = [style.submenu];
			if (this.state.isMoreItemsExpanded) {
				moreItemsSubmenuClasses.push(style.open);
			}

			moreItems = (
				<li class={moreItemsSubmenuClasses.join(' ')}
				    id="hw_more_items_submenu"
				    onMouseOver={this.moreItemsMouseover}
				    onMouseOut={this.moreItemsMouseout}>

					<span class={style.submenu_header} onClick={this.moreItemsMouseover}>
						<span class={style.submenu_dropdown}>
							<img src="../../assets/icons/material/more-vert.svg" alt="" />
						</span>
					</span>

					<SubMenu items={this.moreItemsData}
					         cssClass={style.more_links}
					         activeUrl={props.activeUrl}
					         isExpanded={this.state.isMoreItemsExpanded}
					         stateChange={this.toggleMoreItems} />

				</li>
			);

		} else {

			moreItems = [
				<li>
					<Link activeClassName={style.active} href="/about">About</Link>
				</li>,

				<li>
					<Link activeClassName={style.active} href="/news">News</Link>
				</li>,

				<li>
					<Link activeClassName={style.active} href="/settings">
						<img src="../../assets/icons/material/settings.svg" alt="Settings" />
					</Link>
				</li>
			];

		}

		let isHistoryActive = false;

		for (let i = 0, n = this.historyItemsData.length; i < n; i++) {
			const item = this.historyItemsData[i];
			if ((item.pattern && item.pattern.test(props.activeUrl)) || item.route === props.activeUrl) {
				isHistoryActive = true;
				break;
			}
		}

		const historyHeaderClasses = [style.submenu_header];

		if (isHistoryActive) {
			historyHeaderClasses.push(style.active);
		}

		const historySubmenuClasses = [style.submenu];
		if (this.state.isHistoryExpanded) {
			historySubmenuClasses.push(style.open);
		}

		return (
			<nav class={style.navigation}>
				<ul>
					<li>
						<Link activeClassName={style.active} href="/">Live</Link>
					</li>
					<li>
						<Link activeClassName={style.active} href="/graphs">Graphs</Link>
					</li>
					<li class={historySubmenuClasses.join(' ')}
					    id="hw_history_submenu"
					    onMouseOver={this.historyMouseover}
					    onMouseOut={this.historyMouseout}>

						<span class={historyHeaderClasses.join(' ')} onClick={this.historyMouseover}>
							<span>History</span>
							<span class={style.submenu_dropdown}>
								<DropdownIcon fill="white" />
							</span>
						</span>

						<SubMenu items={this.historyItemsData}
						         cssClass={style.history_links}
						         activeUrl={props.activeUrl}
						         isExpanded={this.state.isHistoryExpanded}
						         stateChange={this.toggleHistoryItems} />

					</li>
					{moreItems}
				</ul>
			</nav>
		);

	}

}

export default sizeAware(Navigation);
