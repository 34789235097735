import style from './style.scss';

/**
 * The Header component displays the header of the website.
 *
 * props: none
 */

const Header = () => (
	<header class={style.header}>
		<h1>Howick Weather Station</h1>
	</header>
);

export default Header;
