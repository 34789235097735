export const ABORT_ERROR_NAME = 'AbortError';

function getAbortController() {
	if ('AbortController' in window) {
		return new AbortController();
	}
	return null;
}

export function abortRequests(requestsObj) {
	for (let key in requestsObj) {
		if (requestsObj.hasOwnProperty(key) && requestsObj[key] !== null) {
			requestsObj[key]();
		}
	}
}

function fetchWithTimeout(uri) {
	const abortController = getAbortController();

	const promise = new Promise((resolve, reject) => {

		let fetchOpts = {};
		let timedOut = false;

		if (abortController !== null) {
			fetchOpts.signal = abortController.signal;
		}

		const timer = setTimeout(() => {
			reject(new Error('Request timed out'));
			timedOut = true;

			if (abortController !== null) {
				abortController.abort();
			}
		}, 20000);

		fetch(uri, fetchOpts)
			.then(res => res.json())
			.then(data => {
				clearTimeout(timer);
				if (data.error) {
					reject(data);
				} else {
					resolve(data);
				}
			})
			.catch(err => {
				clearTimeout(timer);
				if (!timedOut) {
					reject(err);
				}
			});

	});

	return {
		promise,
		abort: abortController === null
			? () => {} // noop
			: () => abortController.abort()
	};
}

function params2QueryString(params) {
	const parts = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
	return '?' + parts.join('&');
}

export function fetchLiveData(params) {
	return fetchWithTimeout(buildUri('/live', params));
}

export function fetchTodayHistory() {
	return fetchWithTimeout(buildUri('/history/today'));
}

export function fetchObservations(params) {
	return fetchWithTimeout(buildUri('/observations', params));
}

export function fetchObservationsHourly(params) {
	return fetchWithTimeout(buildUri('/observations/hourly', params));
}

export function fetchDailyExtremes(params) {
	return fetchWithTimeout(buildUri('/stats/daily', params));
}

export function fetchDataRange(params) {
	return fetchWithTimeout(buildUri('/datarange', params));
}

export function fetchMonthlyStats(params) {
	return fetchWithTimeout(buildUri('/stats/monthly', params));
}

export function fetchRecords() {
	return fetchWithTimeout(buildUri('/records'));
}

export function fetchYearlyRainfall(params) {
	return fetchWithTimeout(buildUri('/stats/rainfall', params));
}

export function fetchWindRoseData(params) {
	return fetchWithTimeout(buildUri('/windrose', params));
}

export function fetchClimateWindRoseData() {
	return fetchWithTimeout(buildUri('/climate/windrose'));
}

export function fetchClimateSummaryData() {
	return fetchWithTimeout(buildUri('/climate/summary'));
}

export function fetchClimateDetailData() {
	return fetchWithTimeout(buildUri('/climate/detail'));
}

export function fetchMessages() {
	return fetchWithTimeout(buildUri('/messages'));
}

export function buildUri(route, params) {
	const api = process.env.NODE_ENV === 'production'
		? 'https://api.howickweather.co.nz'
		: '/api';

	return api + route + (params ? params2QueryString(params) : '');
}
