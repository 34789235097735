import { Component } from 'preact';
import style from './style.scss';
import { MESSAGE_LEVEL } from '../../utils/constants';

/**
 * The Message component displays a message to the user. Content is supplied through props.children.
 *
 * props:
 * - messageId: id of the message
 * - level: see MESSAGE_LEVEL const
 * - dismiss: callback function for clicking the dismiss button
 * - isDismissable: is the message able to be dismissed
 */
class Message extends Component {

	constructor(props) {
		super(props);
		this.dismiss = this.dismiss.bind(this);
	}

	dismiss(evt) {
		if (evt.type === 'keyup' && evt.keyCode !== 13) {
			return;
		}

		this.props.dismiss(this.props.messageId);
	}

	renderDismissBtn() {
		return (
			<div role="button"
			     tabIndex="0"
			     class={style.close_btn}
			     title="Dismiss"
			     onClick={this.dismiss}
			     onKeyUp={this.dismiss}>
				<img src="../../assets/icons/material/close.svg" alt="close" />
			</div>
		);
	}

	render(props) {
		const messageClasses = [style.message];
		let icon;

		if (props.level === MESSAGE_LEVEL.INFO) {

			messageClasses.push(style.level_info);
			icon = 'info';

		} else if (props.level === MESSAGE_LEVEL.WARNING) {

			messageClasses.push(style.level_warning);
			icon = 'warning';

		}

		return (
			<div class={messageClasses.join(' ')}>
				<div class={style.message_icon}>
					{
						icon ? <img src={`../../assets/icons/material/${icon}.svg`} alt={props.level} /> : null
					}
				</div>
				<div class={style.message_content}>
					{ props.children }
				</div>
				{
					props.isDismissable
						? this.renderDismissBtn()
						: null
				}
			</div>
		);
	}

}

export default Message;
