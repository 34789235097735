import { Component } from 'preact';
import style from './style.scss';

/**
 * The SubMenu component renders a submenu with functionality to indicate
 * to the parent component when it should be expanded or collapsed.
 *
 * props:
 * - isExpanded: whether the submenu should be expanded or not
 * - cssClass: additional cssClass to include on the submenu <ul> element
 * - activeUrl: the currently active url within the application
 * - items: the submenu items data. An array containing objects like `{ route: '/about', label: 'About' }
 */
class SubMenu extends Component {

	constructor(props) {
		super(props);

		this.itemFocus = this.itemFocus.bind(this);
		this.itemClick = this.itemClick.bind(this);
		this.itemBlur = this.itemBlur.bind(this);
		this.itemKeyDown = this.itemKeyDown.bind(this);
	}

	emit(isExpanded) {
		if (this.props.isExpanded !== isExpanded) {
			this.props.stateChange(isExpanded);
		}
	}

	// When a submenu item is focused, make sure the menu is expanded.
	itemFocus(evt) {
		// Check if the related target is not null. This fixes strange behaviour where blurring the browser
		// window closes the submenu and then returning to the window re-opens the submenu.
		if (evt.relatedTarget !== null) {
			this.emit(true);
		}
	}

	// When a submenu item is clicked, make sure the menu is collapsed.
	// It's more expected behaviour that when a link is clicked the menu closes.
	itemClick() {
		this.emit(false);
	}

	// When a submenu item is blurred, make sure the menu is collapsed
	// if the next item in itemFocus is outside the submenu.
	itemBlur(evt) {
		if (!this.submenuContainer.contains(evt.relatedTarget)) {
			this.emit(false);
		}
	}

	// When a submenu item is in focus and the enter key is pressed, make sure the
	// menu is collapsed. This situation occurs if the user is tabbing through the menu.
	// They can press enter to follow a link.
	itemKeyDown(evt) {
		if (evt.keyCode === 13) {
			this.emit(false);
		}
	}

	isActive(item) {
		if (item.pattern) {
			return item.pattern.test(this.props.activeUrl);
		}

		return this.props.activeUrl === item.route;
	}

	render(props) {

		const cssClasses = [style.submenu_items];

		if (props.cssClass) {
			cssClasses.push(props.cssClass);
		}

		return (
			<ul class={cssClasses.join(' ')} ref={el => { this.submenuContainer = el; }}>
				{
					this.props.items.map(item => (
						<li>
							<a class={this.isActive(item) ? style.active : null}
							   href={item.route}
							   onClick={this.itemClick}
							   onKeyDown={this.itemKeyDown}
							   onBlur={this.itemBlur}
							   onFocus={this.itemFocus}>
								{ item.label }
							</a>
						</li>
					))
				}
			</ul>
		);

	}

}

export default SubMenu;
