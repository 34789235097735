import { Component } from 'preact';

import { fetchMessages } from '../../utils/api';
import { requestIdleCallbackWithFallback } from '../../utils/request-idle-callback';
import Message from '../message/message';
import { EVENT_ACTION, EVENT_CATEGORY } from '../../utils/tracking';

/**
 * The GlobalMessageList component displays any messages currently available from the API.
 * It allows messages to be dismissed in the UI if the message data specifies this.
 *
 * props: none
 */
class GlobalMessageList extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			messages: null
		};

		this.linkRegex = /<%link:([^:]+):([^(?:%>)]+)%>/g;
		this.savedMessageState = null;

		this.dismiss = this.dismiss.bind(this);
	}

	componentDidMount() {

		fetchMessages().promise
			.then(res => {

				// Later messages come first.
				const messages = res.data;
				messages.sort((m1, m2) => m2.id - m1.id);

				try {
					this.savedMessageState = JSON.parse(window.localStorage.getItem('messages')) || [];
				} catch (e) {
					this.savedMessageState = [];
				}

				let messagesToShow = messages.filter(msg => {

					const msgState = this.savedMessageState.filter(item => item.id === msg.id)[0];

					if (msgState !== undefined) {
						// If an item is found in local storage, the message is seen.
						// We verify it has not been previously dismissed, and the message itself is
						// not hidden once seen.
						return msgState.isDismissed !== true && msg.hideOnceSeen !== true;
					}

					// If it's not in local storage, user hasn't seen it before.
					return true;

				});

				this.setState({ messages: messagesToShow });

				messagesToShow.forEach(msg => {

					// mark items seen in local storage
					const msgState = this.savedMessageState.filter(item => item.id === msg.id)[0];

					if (msgState === undefined) {
						this.savedMessageState.push({
							id: msg.id,
							isSeen: true,
							isDismissed: false
						});
					}

				});

				// remove expired items from local storage
				const activeIds = messagesToShow.map(msg => msg.id);

				this.savedMessageState = this.savedMessageState.filter(msgState => {
					return activeIds.indexOf(msgState.id) !== -1;
				});

				if (messagesToShow.length > 0) {
					// Save local storage
					this.persistSavedMessageState();
				}

			});
	}

	dismiss(messageId) {
		this.setState(state => {
			const messages = state.messages.filter(msg => msg.id !== messageId);
			return { messages };
		});

		const msgState = this.savedMessageState.filter(s => s.id === messageId)[0];

		msgState.isDismissed = true;

		this.context.tracking.recordEvent(EVENT_ACTION.DISMISS_MESSAGE, EVENT_CATEGORY.SETTINGS, `id: ${messageId}`);

		this.persistSavedMessageState();
	}

	persistSavedMessageState() {
		requestIdleCallbackWithFallback(() => {
			try {
				window.localStorage.setItem('messages', JSON.stringify(this.savedMessageState));
			} catch (err) {
				this.context.tracking.recordException('Error saving messages state', err);
			}
		});
	}

	render() {
		if (this.state.messages === null) {
			return null;
		}

		const messages = this.state.messages.map(message => {

			let linkMatch;
			const linkMatches = [];

			// collect all links inside the message content
			do {

				linkMatch = this.linkRegex.exec(message.content);
				if (linkMatch) {
					linkMatches.push(linkMatch);
				}

			} while (linkMatch);

			let content;

			if (linkMatches.length === 0) {

				// No links found. Just return the message content.
				content = <span>{ message.content }</span>;

			} else {

				content = [];
				let index = 0;

				linkMatches.forEach(match => {

					// There is non-link content preceding this link, so first add this to the contents array.
					if (index < match.index) {
						content.push(
							<span>{ message.content.substring(index, match.index) }</span>
						);
					}

					// Now add the link to the contents array.
					content.push(
						<a href={match[1]}>{ match[2] }</a>
					);

					// Update the index to the character following the end of the link.
					index = match.index + match[0].length;

				});

				// Check if there is non-link content remaining at the end of the message, and if so, add it
				// to the content list.
				if (index < message.content.length) {
					content.push(
						<span>{ message.content.slice(index) }</span>
					);
				}

			}

			return (
				<Message key={message.id} level={message.level} isDismissable={message.isDismissable} messageId={message.id} dismiss={this.dismiss}>
					{ content }
				</Message>
			);
		});

		return messages.length > 0
			? <div>{ messages }</div>
			: null;
	}

}

export default GlobalMessageList;
