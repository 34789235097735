import { debounce } from 'debounce';

/**
 * This utility allows as many window resize handlers to be registered as required,
 * while only registering 2 event listeners on the window object (one for resize and
 * one for orientationchange).
 */

export const getResizeEventsHelper = () => {

	const resizeEvents = {

		_callbacks: [],
		_callbackId: 0,

		_resizeHandler: debounce(evt => {
			resizeEvents._callbacks.forEach(callback => callback.handler(evt));
		}, 200),

		_orientationChangeHandler: evt => {
			setTimeout(() => {
				resizeEvents._callbacks.forEach(callback => callback.handler(evt));
			}, 500);
		},

		_register() {
			window.addEventListener('resize', this._resizeHandler);
			window.addEventListener('orientationchange', this._orientationChangeHandler);
		},

		_deregister() {
			window.removeEventListener('resize', this._resizeHandler);
			window.removeEventListener('orientationchange', this._orientationChangeHandler);
		},

		addHandler(handler) {
			// Increment id - each callback needs a unique id
			const id = this._callbackId++;

			// Add the callback into the list
			this._callbacks.push({ id, handler });

			// If we have just gone from 0 callbacks to one, register the event handlers
			if (this._callbacks.length === 1) {
				this._register();
			}

			return id;
		},

		removeHandler(callbackId) {
			// Find the index in the callbacks list corresponding to the provided id
			const index = this._callbacks.map(callback => callback.id).indexOf(callbackId);

			// If callback was found, remove it
			if (index !== -1) {
				this._callbacks.splice(index, 1);
			}

			// If there are no callbacks left, deregister the event handlers
			if (this._callbacks.length === 0) {
				this._deregister();
			}
		}

	};

	return resizeEvents;

};
