import { Component } from 'preact';
import { Router } from 'preact-router';

import LivePage from '../routes/live';
import NewsPage from '../routes/news';
import AboutPage from '../routes/about';
import GraphsPage from '../routes/graphs';
import HistoryDailyPage from '../routes/history-daily';
import HistoryRecordsPage from '../routes/history-records';
import HistoryMonthlyPage from '../routes/history-monthly';
import HistoryYearlyPage from '../routes/history-yearly';
import HistoryDownloadPage from '../routes/history-download';
import HistoryRainfallPage from '../routes/history-rainfall';
import NotFoundPage from '../routes/not-found';

import Header from './header';
import Navigation from './navigation';
import { getResizeEventsHelper } from '../utils/resize-events';
import { getUserSettings } from '../utils/user-settings';
import HistoryClimatePage from '../routes/history-climate';
import SettingsPage from '../routes/settings';
import GlobalMessageList from './global-message-list';
import style from './app.scss';
import { Tracker } from '../utils/tracking';
import { TitleService } from '../utils/title-service';
import Footer from './footer';
import OfflineMessage from './offline-message';
import UpdateAvailable from './update-available';

export default class App extends Component {

	constructor(props) {
		super(props);

		this.state = {
			activeUrl: window.location.pathname,
			isOnline: 'onLine' in window.navigator ? window.navigator.onLine : true,
			isUpdating: false,
			isUpdateAvailable: false,
			monthlyHistoryView: null
		};

		this.handleRouteChange = this.handleRouteChange.bind(this);
		this.installUpdate = this.installUpdate.bind(this);

		this.tracking = Tracker();
		this.titleService = TitleService();
		this.resizeEvents = getResizeEventsHelper();
		this.userSettings = getUserSettings(this.tracking);
		this.monthlyHistoryView = {
			set: view => this.setMonthlyHistoryView(view),
			get: () => this.state.monthlyHistoryView
		};
	}

	handleRouteChange(evt) {
		this.setState({ activeUrl: evt.url });
	}

	setMonthlyHistoryView(view) {
		this.setState({ monthlyHistoryView: view });
	}

	getChildContext() {
		return {

			resize: this.resizeEvents,
			userSettings: this.userSettings,
			tracking: this.tracking,
			titleService: this.titleService,
			monthlyHistoryView: this.monthlyHistoryView,

			// provide callback for updating units, to minimise local storage reads
			onUnitUpdate: () => {
				this.userSettings = getUserSettings(this.tracking);
			}

		};
	}

	componentDidMount() {
		// Remove splash screen from DOM
		const splash = document.getElementById('hw_splash_screen');
		splash.parentNode.removeChild(splash);
		const splashCss = document.getElementById('hw_splash_screen_styles');
		splashCss.parentNode.removeChild(splashCss);

		window.addEventListener('offline', () => {
			this.setState({ isOnline: false });
		});

		window.addEventListener('online', () => {
			this.setState({ isOnline: true });
		});

		if ('serviceWorker' in navigator) {
			window.HowickWeather.whenUpdateAvailable(() => {
				this.setState({ isUpdateAvailable: true });
			});
		}
	}

	installUpdate() {
		this.setState({ isUpdating: true });
		window.HowickWeather.installUpdate();
	}

	render() {
		return (
			<div class={style.page}>
				<Header />
				<Navigation activeUrl={this.state.activeUrl} />
				<div class={style.content_wrapper}>
					{
						this.state.isUpdateAvailable && !this.state.isUpdating
							? <UpdateAvailable installUpdate={this.installUpdate} />
							: null
					}
					{
						this.state.isOnline
							? null
							: <OfflineMessage />
					}
					<GlobalMessageList />
					<div class={style.content}>
						<Router onChange={this.handleRouteChange}>
							<LivePage path="/" isOnline={this.state.isOnline} />
							<GraphsPage path="/graphs" />
							<HistoryDailyPage path="/history-daily/:date?" />
							<HistoryMonthlyPage path="/history-monthly/:month?" />
							<HistoryYearlyPage path="/history-yearly/:year?" />
							<HistoryRecordsPage path="/history-records/:view/:subview?" />
							<HistoryRainfallPage path="/history-rainfall" />
							<HistoryClimatePage path="/history-climate" />
							<HistoryDownloadPage path="/history-download/:view" />
							<AboutPage path="/about" />
							<NewsPage path="/news" />
							<SettingsPage path="/settings" />
							<NotFoundPage path="/not-found" />
							<NotFoundPage default />
						</Router>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}
