import style from './style.scss';

/**
 * Shows an install update button when an application update is available.
 * props:
 * - installUpdate: handler function for button click
 */
const UpdateAvailable = (props) => (
	<div class={style.update_available}>
		<span>An updated version is available.</span>
		<button onClick={props.installUpdate}>Update now</button>
	</div>
);

export default UpdateAvailable;
