import { PRESSURE_UNIT, RAIN_UNIT, SPEED_UNIT, TEMPERATURE_UNIT } from './constants';
import { convertOldPreferencesToNewUserSettings } from './preferences-converter';

export const getUserSettings = (tracking) => {

	let settings = readSettings();

	if (settings === null) {

		// Check if the user has a preferences cookie (legacy)
		const prefsCookieMatch = document.cookie.match(/preferences=([^;]+)/);

		if (prefsCookieMatch !== null) {
			let prefs;
			try {
				prefs = JSON.parse(window.decodeURIComponent(prefsCookieMatch[1]));
			} catch (e) {}

			if (prefs) {
				// convert legacy units to new units and save to local storage
				convertOldPreferencesToNewUserSettings(prefs, tracking);

				// read in the settings just saved.
				settings = readSettings();
			}
		}

		if (settings === null) {
			settings = getDefaultSettings();
		}

	}

	return {
		units: {
			temperature: settings.temperatureUnit,
			speed: settings.speedUnit,
			rain: settings.rainUnit,
			pressure: settings.pressureUnit
		},
		ui: {
			alwaysDisplayRecentObsAsList: false, // settings.alwaysDisplayRecentObsAsList,
			alwaysExpandRecentObsList: false, // settings.alwaysExpandRecentObsList,
			liveUpdatesEnabled: settings.liveUpdatesEnabled
		}
	};

};

const readSettings = () => {
	let settings;

	try {
		settings = JSON.parse(window.localStorage.getItem('user-settings'));
	} catch (e) {
		settings = null;
	}

	return settings;
};

export const getDefaultSettings = () => {
	return {
		temperatureUnit: TEMPERATURE_UNIT.C,
		speedUnit: SPEED_UNIT.KMH,
		rainUnit: RAIN_UNIT.MM,
		pressureUnit: PRESSURE_UNIT.HPA,
		alwaysDisplayRecentObsAsList: false,
		alwaysExpandRecentObsList: false,
		liveUpdatesEnabled: true
	};
};
