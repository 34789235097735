import { MESSAGE_LEVEL } from '../../utils/constants';
import Message from '../message/message';

/**
 * Displays a message that the user is offline
 * props: none
 */
const OfflineMessage = () => (
	<Message level={MESSAGE_LEVEL.WARNING}>
		It appears you are offline. Weather data can't be loaded while offline, so please retry when you're back online.
	</Message>
);

export default OfflineMessage;
