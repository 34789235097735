import { PRESSURE_UNIT, RAIN_UNIT, SPEED_UNIT } from './constants';
import { EVENT_ACTION, EVENT_CATEGORY } from './tracking';
import { getDefaultSettings } from './user-settings';

/**
 * Helper function which converts legacy preferences into new settings data
 * and saves it to local storage.
 * @param prefs
 * @param tracking
 */
export function convertOldPreferencesToNewUserSettings(prefs, tracking) {

	let pressureUnit, rainUnit, speedUnit;

	switch (prefs['unit-pressure']) {
		case 'inhg':
			pressureUnit = PRESSURE_UNIT.INHG;
			break;
		default:
			pressureUnit = PRESSURE_UNIT.HPA;
	}

	switch (prefs['unit-rainfall']) {
		case 'inches':
			rainUnit = RAIN_UNIT.IN;
			break;
		default:
			rainUnit = RAIN_UNIT.MM;
	}

	switch (prefs['unit-windspeed']) {
		default:
			speedUnit = SPEED_UNIT.KMH;
	}

	const temperatureUnit = prefs['unit-temperature'].toUpperCase();

	const settings = {
		...getDefaultSettings(),
		temperatureUnit,
		pressureUnit,
		rainUnit,
		speedUnit
	};

	const settingsStr = JSON.stringify(settings);

	try {

		window.localStorage.setItem('user-settings', settingsStr);
		document.cookie = 'preferences=; expires=Thu, 01 Jan 1970 00:00:00 GMT';

		tracking.recordEvent(EVENT_ACTION.SETTINGS_CONVERT, EVENT_CATEGORY.SETTINGS);

	} catch (e) {

		tracking.recordException('Error converting old preferences to new settings', e);

	}

}
