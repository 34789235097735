export const Tracker = () => ({

	recordPageView: () => {
		if ('ga' in window) {
			ga('send', 'pageview', window.location.pathname);
		} else {
			console.log('[Tracking] Page View', window.location.pathname);
		}
	},

	recordEvent: (action, category, label, value) => {
		if ('ga' in window) {
			ga('send', 'event', category, action, label, value);
		} else {
			console.log('[Tracking] Event', action, label, value);
		}
	},

	recordException: (description, err, data = null) => {

		const errStr = err ? (' error = ' + JSON.stringify(err, Object.getOwnPropertyNames(err))) : '';
		const dataStr = data ? (' data = ' + JSON.stringify(data)) : '';

		const exceptionDesc = description + ':' + dataStr + errStr;

		if ('ga' in window) {
			ga('send', 'exception', {
				exDescription: exceptionDesc
			});
		} else {
			console.log('[Tracking] Exception', exceptionDesc);
		}
	}

});

export const EVENT_CATEGORY = {
	LIVE: 'live',
	GRAPHS: 'graphs',
	ABOUT: 'about',
	NEWS: 'news',
	SETTINGS: 'settings',
	HISTORY_DAILY: 'history_daily',
	HISTORY_MONTHLY: 'history_monthly',
	HISTORY_YEARLY: 'history_yearly',
	HISTORY_RAINFALL: 'history_rainfall',
	HISTORY_RECORDS: 'history_records',
	HISTORY_CLIMATE: 'history_climate',
	HISTORY_DOWNLOAD: 'history_download'
};

export const EVENT_ACTION = {

	DISMISS_MESSAGE: 'dismiss_message',

	SETTINGS_SAVE: 'save_custom_settings',
	SETTINGS_RESET: 'reset_settings',
	SETTINGS_CONVERT: 'convert_settings',

	LIVE_TOGGLE_OBS_VIEW: 'toggle_recent_obs_view_%1',
	LIVE_RESTART_UPDATES: 'restart_live_updates',

	GRAPHS_SWITCH: 'graphs_view_%1_%2',
	GRAPHS_REFRESH: 'graphs_refresh',

	HISTORY_DAILY_TOGGLE_OBS_VIEW: 'daily_history_toggle_obs_view_%1',
	HISTORY_DAILY_SWITCH_DETAILED_OBS_PARAMETER: 'daily_history_switch_detailed_obs_%1',

	HISTORY_MONTHLY_TOGGLE_OBS_VIEW: 'monthly_history_toggle_obs_view_%1',
	HISTORY_MONTHLY_SWITCH_DETAILED_OBS_PARAMETER: 'monthly_history_switch_detailed_obs_%1',

	HISTORY_YEARLY_TOGGLE_GRAPH_VIEW: 'yearly_history_toggle_graph_view_%1',
	HISTORY_YEARLY_SWITCH_DETAILED_GRAPHS_PARAMETER: 'yearly_history_switch_detailed_obs_%1',

	HISTORY_RAINFALL_HIDE_YEAR: 'rainfall_history_hide_year',
	HISTORY_RAINFALL_SHOW_YEAR: 'rainfall_history_show_year',

	HISTORY_CLIMATE_SWITCH_DATA_TABLE_VIEW: 'climate_switch_data_table_view_%1',

	HISTORY_DOWNLOAD_DAILY_STATS: 'download_daily_stats',
	HISTORY_DOWNLOAD_MONTHLY_STATS: 'download_monthly_stats',
	HISTORY_DOWNLOAD_OBSERVATIONS: 'download_observations'

};
