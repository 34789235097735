export const TEMPERATURE_UNIT = {
	C: 'C',
	F: 'F'
};

export const SPEED_UNIT = {
	KMH: 'km/h',
	MPH: 'mph',
	KNOTS: 'knots',
	MS: 'm/s'
};

export const RAIN_UNIT = {
	MM: 'mm',
	IN: 'in.'
};

export const PRESSURE_UNIT = {
	HPA: 'hPa',
	INHG: 'in. Hg'
};

export const GRAPH_VIEW = {
	COMBINED: 'combined',
	TEMPERATURE: 'temperature',
	WIND: 'wind_speed_direction',
	RAIN: 'daily_rainfall',
	HUMIDITY: 'humidity',
	PRESSURE: 'pressure',
	RAIN_HOURLY: 'hourly_rainfall',
	WIND_ROSE: 'wind_rose'
};

export const GRAPH_RANGE = {
	STANDARD: '24h',
	EXTENDED: '7d'
};

export const MESSAGE_LEVEL = {
	INFO: 'info',
	WARNING: 'warning'
};

export const CLIMATE_DATA_TABLE_VIEW = {
	SUMMARY: 'climate_summary',
	AVG_TEMPERATURE: 'mean_temperature',
	AVG_DAILY_HIGH: 'mean_daily_high_temperature',
	AVG_DAILY_LOW: 'mean_daily_low_temperature',
	HIGHEST_TEMPERATURE: 'highest_temperature',
	LOWEST_TEMPERATURE: 'lowest_temperature',
	WARMEST_DAY: 'warmest_day',
	COLDEST_DAY: 'coldest_day',
	LOWEST_DAILY_HIGH: 'lowest_daily_high_temperature',
	HIGHEST_DAILY_LOW: 'highest_daily_low_temperature',
	TOTAL_RAINFALL: 'total_rainfall',
	WET_DAYS: 'number_of_wet_days',
	WETTEST_DAY: 'highest_daily_rainfall',
	HIGHEST_HOURLY_RAINFALL: 'highest_hourly_rainfall',
	HIGHEST_RAIN_RATE: 'highest_rain_rate',
	HIGHEST_WIND_GUST: 'highest_wind_gust',
	HIGHEST_WIND_RUN: 'highest_daily_wind_run',
	LOWEST_WIND_RUN: 'lowest_daily_wind_run',
	HIGHEST_PRESSURE: 'highest_pressure',
	LOWEST_PRESSURE: 'lowest_pressure',
	HIGHEST_DEWPOINT: 'highest_dewpoint',
	LOWEST_DEWPOINT: 'lowest_dewpoint',
	LOWEST_HUMIDITY: 'lowest_humidity',
	NUMBER_DAYS_HIGH_TEMP_27_PLUS: 'number_days_high_temp_27plus',
	NUMBER_DAYS_HIGH_TEMP_25_PLUS: 'number_days_high_temp_25plus',
	NUMBER_DAYS_LOW_TEMP_5_BELOW: 'number_days_low_temp_5below',
	NUMBER_DAYS_LOW_TEMP_2_BELOW: 'number_days_low_temp_2below',
	NUMBER_DAYS_HIGH_DEWPOINT_20_PLUS: 'number_days_high_dewpoint_20plus'
};
